<template>
    <div style="width: 100%">
        <span v-if="label" v-text="label" class="text-body-2"></span>
        <v-card class="px-3 d-flex" width="100%" :elevation="background ? elevation : 0" :class="{ 'transparent': !background }">
            <v-icon v-if="handle" class="mr-4 handle" small>fas fa-bars</v-icon>
            <v-text-field
                dense
                single-line
                :readonly="!update"
                v-model="value"
                @click.prevent.stop="changeState('click')"
                @blur="changeState('blur')"
                :class="{ textStyleInput: !update }"
                v-if="!longInput"
                :placeholder="placeholder"
                hide-details
            ></v-text-field>
            <v-textarea
                v-else
                :readonly="!update"
                v-model="value"
                @click="changeState('click')"
                @blur="changeState('blur')"
                :class="{ textStyleInput: !update, 'pa-0': small, 'py-4': !small }"
                auto-grow
                rows="1"
                class="mt-0"
                :placeholder="placeholder"
                hide-details
            ></v-textarea>
            <v-btn
                icon
                x-small
                @click.stop="remove"
                class="mt-2"
                v-if="removable"
            >
                <v-icon x-small>fas fa-times</v-icon>
            </v-btn>
        </v-card>
        <v-tooltip bottom v-if="bloom && currentBloom">
            <template v-slot:activator="{ on, attrs }">
                <v-card
                    class="float-right mt-n2 mr-5"
                    tile
                    elevation="0"
                    width="40px"
                    height="3px"
                    :color="currentBloom.color"
                    v-bind="attrs"
                    v-on="on"
                ></v-card>
            </template>
            <span>{{ currentBloom.name }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "debounce";

export default {
    name: "CustomInput",
    props: {
        type: {
            type: String,
            require: true,
        },
        objectId: {
            type: String,
            require: true,
        },
        valueName: {
            type: String,
            require: false,
            default: "name",
        },
        longInput: {
            type: Boolean,
            require: false,
            default: false,
        },
        background: {
            type: Boolean,
            require: false,
            default: true,
        },
        removable: {
            type: Boolean,
            require: false,
            default: false,
        },
        mutable: {
            type: Boolean,
            require: false,
            default: true,
        },
        label: {
            type: String,
            require: false,
            default: "",
        },
        elevation: {
            type: String,
            require: false,
            default: "2",
        },
        placeholder: {
            type: String,
            require: false,
            default: "",
        },
        small: {
            type: Boolean,
            require: false,
            default: false,
        },
        bloom: {
            type: Boolean,
            require: false,
            default: false,
        },
        handle: {
            type: Boolean,
            require: false,
            default: false,
        }
    },

    data: () => ({
        update: false,
    }),

    created() {
        this.debouncedSetter = debounce(function(val) {
            this.updateStore(val);
        }, 1000);
    },

    computed: {
        ...mapState("global", ["bloomList"]),

        element: function() {
            return this.getter();
        },

        value: {
            set(val) {
                this.debouncedSetter(val);
            },
            get() {
                return this.element[this.valueName] || "";
            },
        },

        currentBloom: function() {
            return this.bloomList.find(x => x.name === this.element.bloom);
        }
    },

    methods: {
        ...mapActions("formations", [
            "modifyFormation",

            "modifyEducationalObjective",
            "modifyOperationalObjective",
            "modifySpecificObjective",
            "modifyVirtualClass",
            "modifyTeachingProcess",

            "removeEducationalObjective",
            "removeOperationalObjective",
            "removeSpecificObjective",
        ]),

        changeState(origin) {
            if(this.mutable === false) {
                return;
            }

            if (this.update && origin === "blur") {
                this.update = false;
                this.debouncedSetter.flush();
            } else if (!this.update && origin === "click") {
                this.update = true;
            }
        },

        updateStore(val) {
            const updatedObject = {
                [this.valueName]: val,
            };

            if (this.valueName === "name" && (this.type === "educational" || this.type === "operational" || this.type === "specific")) {
                const words = val.replace(/([ .'"]+)/g, "§sep§").split("§sep§");

                this.bloomList.forEach((element) => {
                    words.forEach((word) => {
                        if (element.words.map(name => name.toLowerCase()).includes(word.toLowerCase())) {
                            updatedObject.bloom = element.name;
                        }
                    });
                });
                if(!updatedObject.bloom) {
                    updatedObject.bloom = "";
                }
            }


            if(this.type === "formation") {
                this.setter(updatedObject);
            } else {
                this.setter({ id: this.objectId, ...updatedObject });
            }
        },

        getter: function() {
            let getter;

            if (this.type === "formation") {
                getter = this.$store.getters["formations/getFormationById"];
            } else if (this.type === "educational") {
                getter = this.$store.getters["formations/getEducationalObjectiveById"];
            } else if (this.type === "operational") {
                getter = this.$store.getters["formations/getOperationalObjectiveById"];
            } else if (this.type === "specific") {
                getter = this.$store.getters["formations/getSpecificObjectiveById"];
            } else if (this.type === "virtual") {
                getter = this.$store.getters["formations/getVirtualClassById"];
            } else if (this.type === "teaching") {
                getter = this.$store.getters["formations/getTeachingProcessById"];
            }

            return getter(this.objectId);
        },

        setter: function(params) {
            let setter;

            if (this.type === "formation") {
                setter = this.modifyFormation;
            } else if (this.type === "educational") {
                setter = this.modifyEducationalObjective;
            } else if (this.type === "operational") {
                setter = this.modifyOperationalObjective;
            } else if (this.type === "specific") {
                setter = this.modifySpecificObjective;
            } else if (this.type === "virtual") {
                setter = this.modifyVirtualClass;
            } else if (this.type === "teaching") {
                setter = this.modifyTeachingProcess;
            }

            setter(params);
        },

        remove: function() {
            let remover;

            if (this.type === "educational") {
                remover = this.removeEducationalObjective;
            } else if (this.type === "operational") {
                remover = this.removeOperationalObjective;
            } else if (this.type === "specific") {
                remover = this.removeSpecificObjective;
            }

            remover(this.objectId);
        },
    },
};
</script>

<style scoped>
.textStyleInput >>> .v-input__slot::before,
.textStyleInput >>> .v-input__slot::after {
    content: none !important;
}
</style>
