<template>
    <v-row>
        <v-card width="100%" class="my-8 px-5 rounded-0 elevation-1" height="64px">
            <v-card-text>
                <v-row>
                    <template v-if="formation">
                    <v-col md="10" class="align-self-center">
                        <custom-input
                            type="formation"
                            :object-id="formation.id"
                            value-name="title"
                            elevation="0"
                        ></custom-input>
                    </v-col>
                    <v-col md="1" class="align-self-center">
                        {{ totalLearnerDuration | durationToHuman }}
                    </v-col>
                    </template>
                    <template v-else>
                        <v-col md="11" class="align-self-center">
                            <span class="text-body-1 black--text">{{ synopsis.title }}</span>
                        </v-col>
                    </template>
                    <v-col md="1" class="align-self-center">
                        <DownloadButton></DownloadButton>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CustomInput from "../CustomInput";
import DownloadButton from "./DownloadButton";

export default {
    name: "FormationHeader",
    components: { DownloadButton, CustomInput },
    methods: {
        ...mapGetters("formations", ["getFormationById"]),
        ...mapGetters("synopsis", ["getSynopsisById"]),
    },

    computed: {

        ...mapState("formations", ["currentFormation", "educationalObjectives"]),
        ...mapState("synopsis", ["currentSynopsis"]),
        ...mapState("settings", ["user"]),

        totalLearnerDuration: function() {
            return this.educationalObjectives.reduce((acc, current) => { return acc + current.learnerDuration }, 0);
        },

        formation: function() {
            return this.getFormationById()(this.currentFormation);
        },

        synopsis: function() {
            return this.getSynopsisById()(this.currentSynopsis);
        },
    }
};
</script>

<style scoped>

</style>